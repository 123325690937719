<template>
  <asterix-section>
    <template #content>
      <sun-data-table hoverable striped :headers="headers" :content="items" :loading="isLoading" class="mt-2">
        <template #[`col.name`]="{ item }">
          <sun-data-table-cell :title="item.name">
            <country-flag class="align-middle" :country="item.iso" size="normal" />
            <span class="ml-2 align-middle">{{ item.name }}</span>
          </sun-data-table-cell>
        </template>

        <template #[`col.languages`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass" :title="item.name">
            <span v-for="(language, index) in item.languages" :key="index">
              <sun-badge color="green" class="text-xs context-badge mr-1">
                {{ language | nameFromLocale }}
              </sun-badge>
            </span>
          </sun-data-table-cell>
        </template>

        <template #[`col.mainLanguage`]="{ item }">
          <sun-data-table-cell :title="item.name">
            {{ item.mainLanguage | nameFromLocale }}
          </sun-data-table-cell>
        </template>

        <template #empty>
          <asterix-no-data class="bg-white" />
        </template>
      </sun-data-table>
    </template>
  </asterix-section>
</template>

<script>
import { mapActions } from 'vuex';
import CountryFlag from 'vue-country-flag';
import AsterixSection from '@/components/templates/AsterixSection';
import { getCountries } from '@/services/modules/ecommerce/country';
import CONFIG from './config';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'CountryList',
  components: {
    AsterixSection,
    CountryFlag,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  filters: {
    nameFromLocale: value => {
      const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });
      if (!value) return '';
      try {
        return regionNamesInEnglish.of(value);
      } catch {
        return value;
      }
    },
  },
  data: () => ({
    headers: CONFIG.HEADERS,
    items: [],
    isLoading: false,
  }),
  async mounted() {
    this.isLoading = true;
    try {
      const { data } = await getCountries();
      this.items = data;
    } catch {
      this.createToast(Toast.error('Countries', 'Error getting countries'));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
  },
};
</script>
