var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("sun-data-table", {
              staticClass: "mt-2",
              attrs: {
                hoverable: "",
                striped: "",
                headers: _vm.headers,
                content: _vm.items,
                loading: _vm.isLoading,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `col.name`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          { attrs: { title: item.name } },
                          [
                            _c("country-flag", {
                              staticClass: "align-middle",
                              attrs: { country: item.iso, size: "normal" },
                            }),
                            _c("span", { staticClass: "ml-2 align-middle" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.languages`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          { class: columnClass, attrs: { title: item.name } },
                          _vm._l(item.languages, function (language, index) {
                            return _c(
                              "span",
                              { key: index },
                              [
                                _c(
                                  "sun-badge",
                                  {
                                    staticClass: "text-xs context-badge mr-1",
                                    attrs: { color: "green" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("nameFromLocale")(language)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.mainLanguage`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          { attrs: { title: item.name } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("nameFromLocale")(item.mainLanguage)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("asterix-no-data", { staticClass: "bg-white" }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }