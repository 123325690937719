/// <reference path="../../../../entities/ecommerce/Advertiser.js" />

import { api } from '.';
import { COUNTRY_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = () => ({
  [COUNTRY_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Country[],meta:EndpointMeta}>}
 */
export async function getCountries(params = new QueryParamsBuilder(1, 300)) {
  const partialUrl = api.createUrl(getResources());

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
