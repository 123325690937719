import Column from '@/model/shared/Column';

export default {
  HEADERS: [
    new Column('ISO', 'iso'),
    new Column('ISO3', 'iso3'),
    new Column('COUNTRY', 'name'),
    new Column('LANG', 'languages').setClass('whitespace-pre-line'),
    new Column('MAIN_LANG', 'mainLanguage'),
    new Column('CONTINENT', 'continent'),
  ],
};
